<template>
  <div>
    <el-alert
      class="page_section_card"
      :title="`最后更新时间：${last_time}`"
      type="warning"
      :closable="false"
      show-icon
    >
    </el-alert>
    <el-card header="创作者交易数据" class="page_section_card">
      <keep-alive>
        <dataFilterCompVue
          ref="searchComp"
          :isType="false"
          @search="search"
        ></dataFilterCompVue>
      </keep-alive>
    </el-card>
    <el-card v-loading="loading">
      <el-table :data="tableList" border stripe>
        <el-table-column
          v-for="item in creatorTransactionCols"
          :key="item.label"
          :label="item.label"
          :prop="Array.isArray(item.prop) ? '' : item.prop"
          :align="item.align"
          :min-width="item.width"
          :fixed="item.fixed"
          :sortable="item.sortable"
        >
          <template slot-scope="scope">
            <div v-if="Array.isArray(item.prop)">
              <p>{{ scope.row[item.prop[0]] }}</p>
              <p>至</p>
              <p>{{ scope.row[item.prop[1]] }}</p>
            </div>
            <span v-else>
              <span v-if="item.noFormat">{{ scope.row[item.prop] }}</span>
              <span v-else-if="item.prop == 'payment_status'">
                {{ payment_status_array[scope.row[item.prop]] }}
              </span>
              <span v-else>{{
                common.formatNumber(scope.row[item.prop], item.toThunsands || 2)
              }}</span>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <pagination-vue
        @sizeChange="sizeInit"
        @currentChange="getList"
        :pageSize="postdata.page_num"
        :total="total"
        :firstPage="firstPage"
      />
    </el-card>
  </div>
</template>
<script>
import creatorTransactionCols from "@/assets/mcnTableCols/creatorTransactionCols.js";
import paginationVue from "@/components/paginationVue.vue";
import dataFilterCompVue from "../components/dataFilterComp.vue";
export default {
  components: {
    dataFilterCompVue,
    paginationVue,
  },
  data() {
    return {
      creatorTransactionCols: creatorTransactionCols,
      loading: false,
      last_time: "",
      postdata: {
        page: 1,
        page_num: 20,
      },
      payment_status_array: {
        1: "待打款",
        2: "已打款",
        3: "打款失败",
      },
      tableList: [],
      total: 0,
      firstPage: 1,
    };
  },
  mounted() {
    this.$refs.searchComp.search();
  },
  methods: {
    search(data) {
      this.postdata = Object.assign(this.postdata, data);
      this.postdata.page = 1;
      this.getList(1);
    },
    getList(page) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.postdata.page = page || this.firstPage;
      this.axios(
        "get",
        `${this.$pythonHost}tiktokshop/creator_list`,
        this.postdata
      )
        .then((res) => {
          this.loading = false;
          if (res.code == 1) {
            this.tableList = res.data.list;
            this.total = res.data.total_count;
            this.firstPage = res.data.page;
            this.last_time = res.data.last_time;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    sizeInit(size) {
      this.postdata.page_num = size || 20;
      this.getList(1);
    },
  },
};
</script>
